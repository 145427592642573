import React, { PureComponent } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import omit from 'lodash/omit';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { normalizeString } from '@dmm/lib-common/lib/formatting';
import { ToolSetOptionsItem, ToolSetOptions } from '../../ToolSet';
import { MAX_MAKE_COUNT, MAX_MODEL_COUNT } from '../../../constants/boats';
import { toggleSubFacet, emptySubFacet } from '../../../utils/multiFacetHelper';
import { CollapsibleContent, CollapsibleHeader, Collapsible } from '../../Collapsible';
import {hyphenateUrlComponents, generateSearchPath, hyphenateTrimmed} from '../../../utils/urlHelpers/listings';
import { FilterTypeAhead } from '../';
import Link from '../../SEO/Link';

export class FilterMake extends PureComponent {
  state = {
    typeAheadText: '',
    showAll: false
  }

  handleToggleMake(make) {
    let { selectedMakeModels } = this.state;
    let models = get(selectedMakeModels, make);
    let newMakeModels;
    if (!models) {
      if (this.props.tracking) {
        this.props.tracking.facetAdded({ 'makeModel': emptySubFacet(make, []) });
      }
      newMakeModels = emptySubFacet(make, selectedMakeModels);
    } else {
      if (this.props.tracking) {
        this.props.tracking.facetRemoved(`make removed - ${make}`);
      }
      newMakeModels = omit(selectedMakeModels, make);
    }
    this.setState({
      selectedMakeModels: newMakeModels
    });

    if (Object.keys(newMakeModels).length !== 1) {
      this.props?.handleMultiDataChange({modelRange: undefined, makeModel: newMakeModels});
    } else {
      this.props.handleDataChange('makeModel', newMakeModels);
    }
  }
  componentDidMount() {
    this.handlePropsUpdate();
  }
  componentDidUpdate() {
    this.handlePropsUpdate();
  }
  handlePropsUpdate() {
    if (this.props.makeModels !== undefined) {
      this.setState({
        selectedMakeModels: this.props.makeModels
      });
    }
  }

  onClickMakeLink = (e) => {
    e.preventDefault();
  }

  renderMakes = (items, selectionCallback) => {
    const { position, makeModels, params, searchPage } = this.props;
    const { selectedMakeModels = makeModels } = this.state;
    return !!items.length && <ToolSetOptions>
      {items
        .map((make, i) => {
          const slugMake = normalizeString(hyphenateTrimmed(make.value));
          const selectedModels = get(selectedMakeModels, slugMake);
          const makeSelected = !!selectedModels;
          return <ToolSetOptionsItem
            type="checkbox"
            key={`Make-${slugMake}-all-${i}`}
            id={`Make-${slugMake}-all`}
            name={`Make-${slugMake}-${position}`}
            value={slugMake}
            selected={makeSelected}
            onClick={(value) => {
              this.handleToggleMake(value);
              selectionCallback(value);
            }}>
            <Link className="make-all-link" href={generateSearchPath({ makeModel: emptySubFacet(slugMake, selectedMakeModels) }, params, true, searchPage)} onClick={this.onClickMakeLink}>{make.value}</Link>
          </ToolSetOptionsItem>;
        }
        )}
    </ToolSetOptions>;
  }
  render() {
    const {
      makes = [],
      loading,
      makeModels = {},
      maxMakeCount = MAX_MAKE_COUNT,
      position,
      params,
      searchPage
    } = this.props;
    const { selectedMakeModels = makeModels } = this.state;
    const showAllMakes = params.modal && params.modal.includes('make');

    return <div data-e2e="srp-search-filter-make-model" className={classnames('search-filter make-model', { loading })}>
      <CollapsibleContent initialState="open">
        <FilterTypeAhead
          items={makes}
          selectedItems={Object.keys(selectedMakeModels)}
          id={`make-type-ahead-${position}`}
          name="Makes"
          placeholder="Search Makes"
          max={maxMakeCount}
          render={this.renderMakes}
          showAll={showAllMakes}
          url={generateSearchPath(params, null, null, searchPage) + 'modal-make/'} />
      </CollapsibleContent>
    </div>;
  }
}

export class FilterModels extends PureComponent {
  render() {
    const {
      makes = [],
      models = {},
      position,
      makeModels = {},
      handleMultiDataChange,
      params,
      tracking,
      searchPage
    } = this.props;

    return makes.reduce((filters, make, i) => {
      const slugMake = normalizeString(hyphenateTrimmed(make.value));
      const selectedMakeModels = find(models, ['value', make.value]);
      if (get(makeModels, slugMake)) {
        return [
          ...filters,
          <FilterModel key={`${slugMake}-${i}`}
            make={make}
            models={selectedMakeModels ? selectedMakeModels.model : []}
            makeModels={makeModels}
            position={position}
            handleMultiDataChange={handleMultiDataChange}
            params={params}
            tracking={tracking}
            searchPage={searchPage} />
        ];
      }
      return filters;
    }, []);
  }
}

export class FilterModel extends PureComponent {
  state = {}

  handleToggleModel = (make, model) => {
    let { selectedMakeModels } = this.state;
    if (this.props.tracking) {
      this.handleTracking(make, model, selectedMakeModels);
    }
    let models = toggleSubFacet(make, model, selectedMakeModels, true);
    let newState = {
      selectedMakeModels: models
    };
    this.setState(newState);
    const oem = get(this.props, 'params.oem');
    const oemOrMakeModel = oem && !isEmpty(oem) ? 'oem' : 'makeModel';
    this.props.handleMultiDataChange({modelRange: undefined,[oemOrMakeModel]: models});
  }

  handleTracking(make, model, selected) {
    let selectedModels = get(selected, make, []);
    if (includes(selectedModels, model)) {
      this.props.tracking.facetRemoved(`model removed - ${model}`);
    } else {
      let models = [];
      models.push(model);
      let trackingModel = { [make]: models };
      this.props.tracking.facetAdded({ 'makeModel': trackingModel });
    }
  }

  componentDidMount() {
    this.handlePropsUpdate();
  }
  componentDidUpdate() {
    this.handlePropsUpdate();
  }
  handlePropsUpdate() {
    if (this.props.makeModels !== undefined) {
      this.setState({
        selectedMakeModels: this.props.makeModels
      });
    }
  }

  onClickModelLink = (e) => {
    e.preventDefault();
  }

  renderModels = (models, selectionCallback) => {
    const { make, position, makeModels, params, searchPage } = this.props;
    const { selectedMakeModels = makeModels } = this.state;
    const slugMake = normalizeString(hyphenateTrimmed(make.value));

    return <ToolSetOptions>
      {
        models
          .map((model, i) => {
            let slugModel =  normalizeString(hyphenateTrimmed(model.value), '.');
            if (slugModel.includes('--')) {
              slugModel = normalizeString(slugModel, '.');
            }
            const urlParameterValue = toggleSubFacet(slugMake, slugModel, selectedMakeModels, true);
            const href = generateSearchPath({ [params.oem && !isEmpty(params.oem) ? 'oem' : 'makeModel']: urlParameterValue }, params, true, searchPage);

            return <ToolSetOptionsItem
              type="checkbox"
              key={`Model-${slugModel}-${i}`}
              id={`Model-${slugModel}`}
              name={`Model-${slugMake}-${position}`}
              value={slugModel}
              selected={includes(get(selectedMakeModels, slugMake, []), slugModel)}
              onClick={(value) => {
                this.handleToggleModel(slugMake, value);
                selectionCallback(value);
              }}>
              <Link data-e2e="srp-search-filter-model-link" className="model-link make-model-link" href={href} onClick={this.onClickModelLink}>{model.value}</Link>
            </ToolSetOptionsItem>;
          })
      }
    </ToolSetOptions>;
  }

  render() {
    const {
      make,
      params,
      loading,
      position,
      searchPage,
      models = [],
      makeModels = {},
      maxModelCount = MAX_MODEL_COUNT
    } = this.props;
    const { selectedMakeModels = makeModels } = this.state;
    const slugMake = normalizeString(hyphenateUrlComponents(make.value));
    const showAllModels = params.modal && params.modal.includes('model');

    return <CollapsibleContent initialState="open" key={`MakeCollapsible-${slugMake}-${position}`} id={`MakeCollapsible-${slugMake}-${position}`}>
      <CollapsibleHeader>
        {make.value} Models
      </CollapsibleHeader>
      <Collapsible>
        <div className={classnames('search-filter model', { loading })}>
          <FilterTypeAhead
            items={models}
            selectedItems={get(selectedMakeModels, slugMake)}
            id={`model-type-ahead-${slugMake}-${position}`}
            name="Models"
            placeholder={`Search ${make.value} Models`}
            max={maxModelCount}
            render={this.renderModels}
            showAll={showAllModels}
            url={generateSearchPath(params, null, null, searchPage) + 'modal-model/'} />
        </div>
      </Collapsible>
    </CollapsibleContent>;
  }
}
